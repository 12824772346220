import React from "react";
import "./style.css";
import PressRelease from '../../../assets/images/press-release.jpg'

const ModalPopup = ({ onClose }) => {
  return (
    <div className="modal">
      <div className="modal-content">
        <span className="close" onClick={onClose}>
          &times;
        </span>
        <img src={PressRelease} alt="press release" className="w-full h-auto" />
      </div>
    </div>
  );
};

export default ModalPopup;
