import React, { useEffect, useCallback } from "react";
import roundBlur from "../../../assets/images/round-blur.svg";
import roundBlueLarge from "../../../assets/images/round-blue-large.svg";
import roundBlueSmall from "../../../assets/images/round-blue-small.svg";
import roundBlurMed from "../../../assets/images/round-blur-med.svg";
import magnetBlue from "../../../assets/images/magnet-blue.svg";
import blueCookie from "../../../assets/images/blue-cookie.svg";
import serviceData from "./service-item";
import ServiceCard from "../../ui/card";
import AOS from "aos";
import "aos/dist/aos.css";
import Particles from "react-particles";
import { loadFull } from "tsparticles";

import "./styles.scss";

const Hero = () => {
  const particlesInit = useCallback(async (engine) => {
    console.log(engine);
    // you can initiate the tsParticles instance (engine) here, adding custom shapes or presets
    // this loads the tsparticles package bundle, it's the easiest method for getting everything ready
    // starting from v2 you can add only the features you need reducing the bundle size
    await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {
    await console.log(container);
  }, []);

  useEffect(() => {
    AOS.init({ duration: 2000 });
    AOS.refresh();
  }, []);

  return (
    <section className={`prod-hero`}>
      {/* <img src={roundBlur} alt="" className="round-blur" />
      <img src={roundBlur} alt="" className="round-blur2" />
      <img src={roundBlueLarge} alt="" className="round-blue-large" />
      <img src={roundBlueLarge} alt="" className="round-blue-large2" />
      <img src={roundBlueSmall} alt="" className="round-blue-small" />
      <img src={magnetBlue} alt="" className="magnet-blue" /> */}

      <div className="">
        <Particles
          id="tsparticles"
          init={particlesInit}
          loaded={particlesLoaded}
          options={{
            fullScreen: { enable: false, zIndex: 0 },
            background: {
              color: {
                value: "#ffffff00",
              },
            },
            fpsLimit: 30,
            interactivity: {
              events: {
                onClick: {
                  enable: true,
                  mode: "push",
                },
                onHover: {
                  enable: true,
                  mode: "repulse",
                },
                resize: true,
              },
              modes: {
                push: {
                  quantity: 4,
                },
                repulse: {
                  distance: 200,
                  duration: 0.4,
                },
              },
            },
            particles: {
              color: {
                value: "#ffffff00",
              },
              links: {
                color: "#ffffff",
                distance: 150,
                enable: false,
                opacity: 0.1,
                width: 1,
              },
              collisions: {
                enable: false,
              },
              move: {
                directions: "none",
                enable: true,
                outModes: {
                  default: "bounce",
                },
                random: false,
                speed: 2,
                straight: false,
              },
              number: {
                density: {
                  enable: true,
                  area: 800,
                },
                value: 30,
              },

              opacity: {
                value: 0.5,
                random: true,
                anim: {
                  enable: true,
                  speed: 1,
                  opacity_min: 0.5,
                  sync: false,
                },
              },

              shape: {
                type: ["circle", "image"],
                image: [
                  {
                    src: roundBlur,
                    height: 70,
                    width: 73,
                  },
                  {
                    src: blueCookie,
                    height: 40,
                    width: 40,
                  },
                  {
                    src: roundBlueSmall,
                    height: 40,
                    width: 40,
                  },
                  {
                    src: roundBlurMed,
                    height: 40,
                    width: 43,
                  },
                  {
                    src: roundBlueLarge,
                    height: 40,
                    width: 43,
                  },
                  {
                    src: magnetBlue,
                    height: 40,
                    width: 43,
                  },
                ],
              },
              size: {
                value: 50,
                random: false,
                anim: {
                  enable: true,
                  speed: 10,
                  size_min: 10,
                  sync: false,
                },
              },
              // size: {
              //   value: { min: 1, max: 5 },
              // },
            },
            detectRetina: false,
          }}
        />
      </div>

      <div className="hero-skew"></div>

      <div className={` wrapper`}>
        <div className={`left`}>
          <h2>Products and Services</h2>
          <p>
            Looking for the best of the best that IT can offer? We see you. You
            don’t have to feel confused about how to get that task done, neither
            do you have to tackle it on your own. Our products and services cut
            across every facets of the tech industry, and be rest assured, we’ve
            got you covered.
          </p>
        </div>
      </div>

      <div data-aos="" className={`services-list`}>
        {serviceData.map((service) => {
          return (
            <ServiceCard
              key={service.id}
              id={service.id}
              image={service.image}
              title={
                service.title === "User Experience (UX)" ? (
                  <p className={`service-list-user`}>{service.title}</p>
                ) : (
                  service.title
                )
              }
              content={service.content}
            />
          );
        })}
      </div>
    </section>
  );
};

export default Hero;
