import React from "react";
import projectData from "./project-items";
import briefPad from "../../../assets/images/brief-pad.png";

import "./styles.scss";
import ProjectCard from "../../ui/card/project-card";

const Projects = () => {
  return (
    <section className={`project-content`}>
      <div className={`wrapper`}>
        <img src={briefPad} alt="" />
        <h2>Our Projects</h2>
        <p>
          Transform your ideas into reality with our full-stack software
          development, UI/UX design, and data analytics services. Our expert
          team of developers and designers specialize in creating cutting-edge
          solutions for your business. From optimizing your DevOps process to
          implementing a Utility Management System, we've got you covered. Let
          us help you streamline your operations and accelerate your growth with
          our comprehensive range of tech services. Here are just a few of our
          standout projects.
        </p>
        <div className="project-list">
          {projectData.map((project) => {
            return (
              <ProjectCard
                key={project.id}
                title={project.title}
                nom={project.nom}
                content={project.content}
                image={project.image}
              />
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Projects;
