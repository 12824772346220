import React from "react";
import roundBlur from "../../../assets/images/round-blur.svg";
import roundBlurMed from "../../../assets/images/round-blur-med.svg";
import roundBlueLarge from "../../../assets/images/round-blue-large.svg";
import roundBlueSmall from "../../../assets/images/round-blue-small.svg";
import blueCookie from "../../../assets/images/blue-cookie.svg";
import userSystem from "../../../assets/images/user-system.png";

import "./styles.scss";

const Hero = () => {
  return (
    <section className={`about-hero`}>
      <img src={roundBlur} alt="" className="round-blur" />
      <img src={roundBlueLarge} alt="" className="round-blue-large" />
      <img src={roundBlurMed} alt="" className="round-blur-med" />
      <img src={roundBlueSmall} alt="" className="round-blue-small" />
      <img src={blueCookie} alt="" className="blue-cookie" />

      <div className="hero-skew"></div>

      <div className={`wrapper`}>
        <div className={`left`}>
          <h2>About Us</h2>
          <p>
            Welcome to Omniswift Nigeria Limited, where technology meets
            innovation! We are more than just a tech company. We are a team of
            passionate tech enthusiasts who are committed to helping businesses
            and organizations achieve their goals through the power of
            technology.
          </p>
        </div>
        <div>
          <div className={`right`}>
            <img src={userSystem} alt="right" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
