import React from "react";
import CareerHero from "./hero";
import Content from "./content";
import { Helmet } from "react-helmet";

const careerUs = () => {
  return (
    <div className='homepage'>
      <Helmet>
        <title>Career | Omniswift</title>
      </Helmet>
      <div className='wrapper'>
        <CareerHero />
        <Content />
      </div>
    </div>
  );
};

export default careerUs;
