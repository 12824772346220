import React, { useEffect } from "react";

import handbulb from "../../../assets/images/hand-bulb.png";
import galleryPen from "../../../assets/images/gallery-pen.png";
import phoneIsmorph from "../../../assets/images/phone-isomorph.png";
import hierachy from "../../../assets/images/hierachy.png";

import AOS from "aos";
import "aos/dist/aos.css";

import "./styles.scss";

const AboutContent = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);
  return (
    <section className={`about-section`}>
      <div data-aos="fade-up" className={`wrapper`}>
        <div className={`left`}>
          <img src={handbulb} alt="bulb" />
        </div>
        <div className={`right`}>
          <div data-aos="fade-right">
          <img src={galleryPen} alt="gallery-pen" />
          </div>
          <div data-aos="fade-up">
            <h3>Our Story</h3>
            <p>
              Established in 2011, we are a team of young and dynamic
              professionals that came together to start a cross-disciplinary
              consulting firm that provide strategic IT consulting, web
              development, and interaction design, with a specialty in the
              enterprise, cloud infrastructure solutions, outsourcing, and
              training. From it’s Abuja headquarters, Omniswift has worked with
              diverse clients across the private and public sectors. With our
              vast experience in interaction design, we can help create an ideal
              path that can retain customers that you have, gain new customers,
              and most likely create efficiencies that will easily be visible on
              the bottom line. Our focus is on finding the right mix of services
              that will deliver impactful and efficient solutions that solve our
              client’s business challenges, backed by our superior customer
              service.
            </p>
          </div>
        </div>
      </div>
      <div data-aos="fade-up" className={`wrapper`}>
        <div className={`right`}>
          <div data-aos="fade-right">
            <img src={galleryPen} alt="gallery-pen" />
          </div>
          <div data-aos="fade-up">
            <h3>Mission</h3>
            <p>
              Our goal is to add value by helping our clients make business
              decisions, using the right information at the right time through
              implementing process driven Solutions
            </p>
          </div>
          <div data-aos="fade-right" className={`vision-margin`}>
            <img src={galleryPen} alt="gallery-pen" />
            <h3>Vision</h3>
            <p>To become the World’s leading Technology Solutions firm</p>
          </div>
        </div>
        <div className={`left`}>
          <img src={phoneIsmorph} alt="phone" />
        </div>
      </div>
      <div data-aos="fade-up" className={`wrapper`}>
        <div data-aos='data-right' className={`left`}>
          <img src={handbulb} alt="bulb" />
        </div>
        <div data-aos='fade-up' className={`right`}>
          <div data-aos="fade-right">
            <img src={galleryPen} alt="gallery-pen" />
          </div>
          <h3>Core Value</h3>
          <p>
            We perceive our clients as our family, working invigorated to bring
            real value to their enterprises. When you get our services, you can
            expect the following qualities from us:
          </p>
          <div>
            <ul>
              <li>Sincerity: You are known for candour and directness</li>
              <li>
                Innovation: You re‐conceptualize issues to discover practical
                solutions to hard problems
              </li>
              <li>
                Self Improvement: You are conscious of your current level of
                productivity and happiness, and make continual changes to grow
              </li>
              <li>
                Excellent Service: You believe in the purposefulness of
                improving the lives of those we serve
              </li>
              <li>
                Collaboration: You share information openly and proactively
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div data-aos="fade-up" className={`stru-wrapper`}>
        <h3>Our Structure</h3>
        <p>
          In the fast-paced world of technology, having a well-designed
          organizational structure is essential to ensure that we can deliver
          innovative solutions to our customers efficiently and effectively. At
          Omniswift, we have developed a structure that supports our mission of
          delivering cutting-edge technology solutions to our clients. Our
          organization is structured around key departments such as business
          development, software development, quality assurance, and customer
          support. Each department is led by experienced professionls who work
          collaboratively to deliver the best possible outcomes for clients. By
          maintaining a structure that fosters collaboration and innovation, we
          can stay ahead of the competition and continue to provide our clients
          with the highest-quality technology solutions.
        </p>
      </div>
      <div data-aos="fade-up" className={`hierachy`}>
        <img src={hierachy} alt="hierachy" />
      </div>
    </section>
  );
};

export default AboutContent;
