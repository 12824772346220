import React from "react";

let ACCORDIONDATA;

ACCORDIONDATA = [
  {
    id: 1,
    role: "Frontend Developer",
    content: "Lorem ipsum dolor sit amet",
  },
  {
    id: 2,
    role: "UI/UX Designer",
    content: "Lorem ipsum dolor sit amet",
  },
  {
    id: 3,
    role: "Backend Developer",
    content: "Lorem ipsum dolor sit amet",
  },
  {
    id: 4,
    role: "Quality Assurance",
    content: "Lorem ipsum dolor sit amet",
  },
  {
    id: 5,
    role: "Product Manager",
    content: "Lorem ipsum dolor sit amet",
  },
];
export default ACCORDIONDATA;
