import React, { useEffect } from "react";
import Accordion from "../../ui/accordion";
import accordionData from "../accordionData";

import AOS from "aos";
import "aos/dist/aos.css";

import "./styles.scss";

const CareerContent = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
    AOS.refresh();
  }, []);
  return (
    <section className={`career-content`}>
      <div data-aos="fade-up" className={`career-container`}>
        <h2>Join Our Team</h2>
        <p>
          Welcome to Omniswift, where we specialize in providing a wide range of
          technology services to our clients. We offer software development,
          UI/UX design, data analytics, DevOps, and utility management system
          services to clients from various industries. We're always on the
          lookout for talented individuals who are passionate about technology
          and innovation to join our team. As a member of our team, you'll have
          the opportunity to work on exciting projects that will challenge and
          grow your skills. Whether you're a developer, designer, analyst, or
          engineer, we believe in providing our employees with the resources
          they need to succeed. We're looking for individuals who are creative,
          collaborative, and driven. If you're someone who thrives in a
          fast-paced environment and is always seeking to improve, we'd love to
          hear from you. We offer a competitive salary and benefits package,
          along with opportunities for career growth and advancement. We also
          believe in promoting a healthy work-life balance, so our employees can
          maintain their passion for technology while also enjoying their
          personal lives. If you're ready to join a dynamic team of tech
          enthusiasts, please take a look at our current job openings below. We
          look forward to hearing from you!
        </p>
        <div className={`accordion-wrapper`}>
          <div data-aos="fade-up">
            <h3>Available Roles</h3>
            {accordionData.map((accordionList) => {
              return (
                <div key={accordionList.id}>
                  <Accordion
                    role={accordionList.role}
                    content={accordionList.content}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CareerContent;
