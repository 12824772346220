import React, { useState, useEffect } from "react";
import HomeHero from "./hero";
import OurToolbox from "./ourToolbox";
import Design from "./design";
import { Helmet } from "react-helmet";
import PressRelease from "../../assets/images/press-release.jpg";
import AdvertPopup from "./advertPop";
import ModalPopup from "./ModalPopup/ModalPopup";

const HomePage = () => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  const closeModal = () => {
    setShowModal(false);
  };
  return (
    <div className="homepage">
      <Helmet>
        <title>Home | Omniswift</title>
      </Helmet>
      <div className="wrapper">
        <HomeHero />
        {showModal && <ModalPopup onClose={closeModal} />}
        <OurToolbox />
        <Design />
      </div>
    </div>
  );
};

export default HomePage;
