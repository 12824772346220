import React from "react";
import ReactDOM from "react-dom";
import "./styles/main.scss";
import "swiper/css/pagination";
// import "../styles/globals.scss";
import "swiper/css";
import "swiper/css/bundle";
// import "animate.css/animate.min.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";

ReactDOM.render(
    <BrowserRouter >
      <App />
    </BrowserRouter>,

  document.getElementById("root")
);

reportWebVitals();
