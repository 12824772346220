import React from "react";
import AboutHero from "./hero";
import Content from "./content";
import { Helmet } from "react-helmet";

const aboutUs = () => {
  return (
    <div className='homepage'>
      <Helmet>
        <title>About Us | Omniswift</title>
      </Helmet>
      <div className='wrapper'>
        <AboutHero />
        <Content />
      </div>
    </div>
  );
};

export default aboutUs;
