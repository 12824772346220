import React from "react";
import roundBlur from "../../../assets/images/round-blur.svg";
import roundBlurMed from "../../../assets/images/round-blur-med.svg";
import roundBlueLarge from "../../../assets/images/round-blue-large.svg";
import roundBlueSmall from "../../../assets/images/round-blue-small.svg";
import blueCookie from "../../../assets/images/blue-cookie.svg";
import bookHandList from "../../../assets/images/book-hand-list.png";
import { Link } from "react-router-dom";

import "./styles.scss";

const Hero = () => {
  return (
    <section className={`project-hero`}>
      <img src={roundBlur} alt="" className="round-blur" />
      <img src={roundBlueLarge} alt="" className="round-blue-large" />
      <img src={roundBlurMed} alt="" className="round-blur-med" />
      <img src={roundBlueSmall} alt="" className="round-blue-small" />
      <img src={blueCookie} alt="" className="blue-cookie" />

      <div className="hero-skew"></div>

      <div className={`wrapper`}>
        <div className={`left`}>
          <h2>Projects</h2>
          <p>
            Our portfolio of successful projects is a testament to our expertise
            in providing top-notch software development, UI/UX design, data
            analytics, and DevOps services. From web applications to mobile
            apps, we have successfully delivered numerous projects across
            various industries. Our team of skilled professionals specializes in
            delivering custom solutions tailored to your unique business needs.
          </p>
          <div className={`btn-mobile`}>
          <Link to="/contact-us" className="btn-primary btn-large btn-toolbox">
            Get Started
          </Link>
          </div>
        </div>
        <div>
          <div className={`right`}>
            <img src={bookHandList} alt="right" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
